// 
// Menu for webeditors
// 




// import stylu z cro_admin_theme do e7
@import "../../e7/sass/01_patternlab/02_variables/_colors.scss";
@import "../../e7/sass/01_patternlab/03_abstractions/_mixins.scss";
@import "../../cro_admin_theme/sass/_webeditor_navbar.scss";

// pretizeni nekterych stylu pro spravne zobrazeni
.admin-top-menu {
	.menu {
		z-index: 99;
		padding: 0 !important;

		// polozka menu
		&__link {
			font-weight: 200;
			color: $color_background-0 !important;
			padding: .18rem 1rem;
			@media only screen and (min-width: $menu_breakpoint) {
				//padding: .5rem 1rem !important;
				padding: 8px 12px !important;
			}
		}

		// polozka menu obsahujiuci submenu
		&--dropdown {
			padding-right: 0 !important;
		}

		// obsah podmenu
		&__dropdown {
			left: 10px;
			top: 22px;
			@media only screen and (min-width: $menu_breakpoint) {
				left: 0;
				top: 32px;
			}
		}
	}
}
