$animation-speed: 550ms;
$promo_bcg: url(../images/src/bg-radiocusticapromo.svg) no-repeat center;
$promo_logo: url(../images/src/logo-radiocusticapromo.svg) no-repeat -45px 5px;

.radiocustica-promo {
	&__wrap {
		display: none; // toto skryti je pro fix duplicit, element je v markupu 2x, nize pretezujeme
		background: $promo_bcg;
		flex-direction: column;
		justify-content: center;
		padding: 25px;
		min-height: 64px;
		text-transform: uppercase;
		text-decoration: none;
		max-width: 200px;
		animation: vyjezd $animation-speed forwards;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 24px;
		@include respond-to('medium') {
			margin-bottom: 0;
		}
		@include respond-to('large') {
			animation: vyjezd-bigscreen $animation-speed forwards;
		}
		&:hover {
			@include respond-to('large') {
				.radiocustica-promo__title {
					text-decoration: none;
				}
			}
		}
		// zmeny, ktere se aplikuji pouze pokud je aktivni bigromo
		& + #page-wrapper {
			#header {
				#logo {
					@include respond-to('medium') {
						display: flex;
						justify-content: center;
						.logo {
							background: $promo_logo;
							background-size: 120%;
							margin-right: 0;
							margin-left: 0;
							height: 100px;
						}
					}
				}
			} 
			// uprava pozice sticky menu pri zobrazeem bigpromu
			main #block-menu-menu-main-menu-radiocustica {
				top: 110px;
			}
		} 
	}
	&__title,
	&__heading {
		color: #ffffff;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0 2px 2px 2px;
		display: flex;
		line-height: 1.2;
		font-size: .85rem;
		text-align: left;
	}
	&__heading {
		text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);
	}
	&__title {
		text-decoration: underline;
		background-color: #000000;
		// z duvodu pozicovani sticky menu si nemuzeme dovolit presprilis dlouhe textace u bigromo, limitovano na 3 radky textu max
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}


// animace
@keyframes vyjezd {
	0% {
		opacity: 0;
		transform: translate(100vw, 0);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
@keyframes vyjezd-bigscreen {
0% {
		opacity: 0;
		transform: translate(0, -100vh);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}	
}	


// zobrazeni / skryti bigpromo v zavislosti na breakpointu
.mobile-not.radiocustica-promo {
	.radiocustica-promo__wrap {
		@include respond-to('small') {
			display: none;
		}
		@include respond-to('medium') {
			display: flex;
		}
	}
}
.mobile-only.radiocustica-promo {
	.radiocustica-promo__wrap {
		display: flex;
		@include respond-to('medium') {
			display: none;
		}
	}
}
