html {
  line-height: 1.22em;
}
body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: 1em;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  font-weight: 700;
  color: $black;
}
h1 {
  font-size: em(30);
}
h2 {
  font-size: em(20);
  font-size: em(20);
}
h3 {
  font-size: em(18);
}
h4 {
  font-size: em(16);
  font-weight: 700;
}
p {
  margin: 0 0 $small-spacing;
}
a {
  color: $action-color;
  text-decoration: none;
  //transition: color $base-duration $base-timing;
  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
    text-decoration: underline;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
