/* http://www.w3schools.com/howto/howto_js_fullscreen_overlay.asp */

$menu-color: black;
//@include respond-to('medium') {
    .main-menu--radiocustica {
        @include respond-to('small-medium') {
            > div.content {
                background: black;
                transition: all .7s ease-in-out;
            }
        }
        //position: relative;
        #responsive-toggler #burger {
            background: url(../images/burger.png) no-repeat 0 0;
            background-image: url(../images/burger.svg);
            background-color: black;
            display: inline-block;
            //background-size: 28px auto;
            width: 94px;
            height: 27px;
            /*@include respond-to('medium') {
                background-size: 38px auto;
                width: 38px;
                height: 33px;
            }*/
        }
        #responsive-toggler {
            display: block;
            //position: fixed;
            //top: 125px;
            //left: 52px;

            margin-top: 10px;
            //margin-left: 52px;
            @include respond-to('small-medium') {
              @include container(85%);
            }
            /*margin-top: 20px;
            @include respond-to('medium') {
              margin-top: 40px;
            }*/
            /*&:hover, &:focus {
                #burger {
                    background-image: url(../images/burger-close.png);
                    background-image: url(../images/burger-close.svg);
                }
            }*/
        }
    }
//}


.main-menu--radiocustica ul.menu {
    display: none;
}

@include respond-to('medium') {
    #responsive-toggler, #closebtn, #burger {
        display: none !important;
    }
    .main-menu--radiocustica {
        //position: static;
        ul.menu {
            display: block;
        }
    }
}
#closebtn {
    display: none;
}
.nav-responsive-on {
    #burger {
        display: none !important;
    }
    #closebtn {
        // as button over toggler
        display: block;
        //position: fixed;
        //top: 125px;
        //left: 52px;
        margin-top: 10px;
        margin-left: 52px;
        @include respond-to('small-medium') {
          @include container(85%);
        }
        background: url(../images/burger-close.png) no-repeat 0 0;
        background-image: url(../images/src/burger-close.svg);
        background-color: black;
        width: 94px;
        height: 27px;
        /*position: fixed;
        top: 0;
        right: 0;
        margin: 0 auto;
        padding: 20px 60px 20px 20px;
        text-align: center;
        font-size: em(14);*/
        z-index: 10000;
        /*&:before, &:after {
            content: '';
            display: block;
            width: 30px;
            height: 5px;
            background: white;
            position: fixed;
            top: 31px;
            right: 20px;
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }*/
    }
    .main-menu--radiocustica ul.menu {
        display: block;
    }
    .main-menu--radiocustica ul.menu {
        /*position: fixed;
        top: 0;
        left: 0;
        right: 0;*/
        /*margin: 0 auto;
        width: 100%;
        height: 100%;
        background-color: rgba($blue, 0.95);*/
        background: none;
        z-index: 1000;
        overflow: hidden;
        backface-visibility: hidden;
        padding-top: 10px;
        //margin-left: 52px;
        @include respond-to('small-medium') {
          @include container(85%);
        }
        li {
            display: block;
            margin: 8px 0 8px 0;
            position: relative;
            a {
                color: white;
                border-bottom: none;
                font-size: em(16);
            }
            &:after {
                display: block;
                content: "";
                width: 100px;
                height: 20px;
                position: absolute;
                top: 2px;
                left: 4em;
            }
            &:hover, &:focus, &.active-trail {
                &:after {
                    background: url(../images/src/menu-mobil-active.svg) no-repeat 0 50%;
                    background-size: 100px auto;
                }
                //background: url(../images/src/menu-mobil-active.svg) no-repeat 4em 50%;
            }
        }
    }
}
