//
// Colors
//



// Debug
$grid: rgba(#b5d7e5, 1);



// Text
$color_text_accent: rgba(#000000, 1);
$color_text_accent_inverted: rgba(#ffffff, 1);

$color_text_paragraph: rgba(#555561, 1);
$color_text_paragraph_node: rgba(#383840, 1);
$color_text_paragraph_inverted: rgba(#dddddd, 1); // TODO tato inverzni potlacena barva textu neni Alesem posvecena alebrz pouze tipnuta

$color_text_link: rgba(#1a1a1e, 1);
$color_text_link_inverted: rgba(#ffffff, 1);

$color_text_page_heading: rgba(#9e9caa, 1);



// Decoration
$color_decoration_main: rgba(#519fd7, 1);
$color_decoration_alert: rgba(#ca003a, 1);
$color_decoration_success: rgba(#2ea666, 1);



// Background fills
$color_background-0: rgba(#ffffff, 1);
$color_background-1: rgba(#f7f7f8, 1);
$color_background-2: rgba(#efeff2, 1);
$color_background-3: rgba(#e4e4e9, 1);
$color_background-4: rgba(#d6d6de, 1);
$color_background-5: rgba(#9e9ea9, 1);
$color_background-6: rgba(#73737f, 1);
$color_background-7: rgba(#555561, 1);
$color_background-8: rgba(#383840, 1);
$color_background-9: rgba(#1a1a1e, 1);


// Background with transparency
$color_background-0_alpha-low: rgba($color_background-0, 0.8);
$color_background-1_alpha-low: rgba($color_background-1, 0.8);
$color_background-2_alpha-low: rgba($color_background-2, 0.8);
$color_background-3_alpha-low: rgba($color_background-3, 0.8);
$color_background-4_alpha-low: rgba($color_background-4, 0.8);
$color_background-5_alpha-low: rgba($color_background-5, 0.8);
$color_background-6_alpha-low: rgba($color_background-6, 0.8);
$color_background-7_alpha-low: rgba($color_background-7, 0.8);
$color_background-8_alpha-low: rgba($color_background-8, 0.8);
$color_background-9_alpha-low: rgba($color_background-9, 0.8);

$color_background-0_alpha-mid: rgba($color_background-0, 0.5);
$color_background-1_alpha-mid: rgba($color_background-1, 0.5);
$color_background-2_alpha-mid: rgba($color_background-2, 0.5);
$color_background-3_alpha-mid: rgba($color_background-3, 0.5);
$color_background-4_alpha-mid: rgba($color_background-4, 0.5);
$color_background-5_alpha-mid: rgba($color_background-5, 0.5);
$color_background-6_alpha-mid: rgba($color_background-6, 0.5);
$color_background-7_alpha-mid: rgba($color_background-7, 0.5);
$color_background-8_alpha-mid: rgba($color_background-8, 0.5);
$color_background-9_alpha-mid: rgba($color_background-9, 0.5);

$color_background-0_alpha-high: rgba($color_background-0, 0.2);
$color_background-1_alpha-high: rgba($color_background-2, 0.2);
$color_background-2_alpha-high: rgba($color_background-2, 0.2);
$color_background-3_alpha-high: rgba($color_background-3, 0.2);
$color_background-4_alpha-high: rgba($color_background-4, 0.2);
$color_background-5_alpha-high: rgba($color_background-5, 0.2);
$color_background-6_alpha-high: rgba($color_background-6, 0.2);
$color_background-7_alpha-high: rgba($color_background-7, 0.2);
$color_background-8_alpha-high: rgba($color_background-8, 0.2);
$color_background-9_alpha-high: rgba($color_background-9, 0.2);



// Divider
$color_divider: rgba(#d6d6de, 1);
$color_divider_inverted: rgba(#f7f7f8, 1);



// CRo Main colors
$color_corporate_main: rgba(#000f37, 1);

$color_portal_main: rgba(#0157aa, 1);

$color_irozhlas_main: rgba(#d52834, 1);

$color_radiozurnal_main: rgba(#ed2e38, 1);
$color_dvojka_main: rgba(#85248f, 1);
$color_vltava_main: rgba(#00b8e0, 1);
$color_plus_main: rgba(#de7008, 1);
$color_wave_main: rgba(#cda200, 1);
$color_d-dur_main: rgba(#ab035c, 1);
$color_jazz_main: rgba(#00809e, 1);

$color_region_main: rgba(#00ab96, 1);
$color_regiony_main: rgba(#00ab96, 1); // Radio vaseho kraje

$color_radiopraha_main: rgba(#000f37, 1);
$color_retro_main: rgba(#000f37, 1);

$color_informace_main: rgba(#0157aa, 1);
$color_program_main: rgba(#0058a9, 1);
$color_zelenavlna_main: rgba(#009645, 1);
$color_prixbohemia_main: rgba(#000f37, 1);
$color_1968_main: rgba(#02091d, 1);
$color_cestyzavodou_main: rgba(#519fd7, 1);
$color_digital_main: rgba(#0157aa, 1);
$color_svetluska_main: rgba(#f9c644, 1);
$color_socr_main: rgba(#000f37, 1);
$color_schodycasu_main: rgba(#b2191b, 1);
$color_concertino_main: rgba(#000f37, 1);
$color_presspektivy_main: rgba(#000f37, 1);




// CRo Main Darker colors
// TODO ne vsechny barvy jsou spravne definovany, je treba dodefinovat a doplnit do atomu barev a gradientu
$color_corporate_main_darker: rgba(#000d31, 1);

$color_portal_main_darker: rgba(#3378bb, 1);

$color_radiozurnal_main_darker: rgba(#d52932, 1);
$color_dvojka_main_darker: rgba(#772080, 1);
$color_vltava_main_darker: rgba(#00a5c9, 1);
$color_d-dur_main_darker: rgba(#682a5a, 1);
$color_plus_main_darker: rgba(#c76407, 1);
$color_wave_main_darker: rgba(#b89100, 1);
$color_jazz_main_darker: rgba(#00738e, 1);

$color_region_main_darker: rgba(#009987, 1);
$color_regiony_main_darker: rgba(#00706e, 1); // Radio vaseho kraje

$color_radiopraha_main_darker: rgba(#000d31, 1);
$color_retro_main_darker: rgba(#000d31, 1);

$color_informace_main_darker: rgba(#004e99, 1);
$color_program_main_darker: rgba(#000d31, 1);
$color_zelenavlna_main_darker: rgba(#00873e, 1);
$color_prixbohemia_main_darker: rgba(#323e5e, 1);
$color_1968_main_darker: rgba(#1b2133, 1);
$color_cestyzavodou_main_darker: rgba(#488fc1, 1);
$color_digital_main_darker: rgba(#3378bb, 1);
$color_svetluska_main_darker: rgba(#f8b816, 1);
$color_socr_main_darker: rgba(#323e5e, 1);
$color_schodycasu_main_darker: rgba(#a01618, 1);
$color_concertino_main_darker: rgba(#323e5e, 1);
$color_presspektivy_main_darker: rgba(#323e5e, 1);



// CRo Add-1 colors
$color_corporate_add-1: rgba(#0058a9, 1);

$color_radiozurnal_add-1: rgba(#9e1f63, 1);
$color_dvojka_add-1: rgba(#412985, 1);
$color_vltava_add-1: rgba(#006aac, 1);
$color_plus_add-1: rgba(#ed1c24, 1);
$color_wave_add-1: rgba(#765f00, 1);
$color_d-dur_add-1: rgba(#682a5a, 1);
$color_jazz_add-1: rgba(#094567, 1);

$color_region_add-1: rgba(#00706d, 1);
//$color_zelenavlna_add-1 zelena vlna nema definovanou variantu add-1



// CRo Add-2 colors
$color_corporate_add-2: rgba(#519fd7, 1);

$color_radiozurnal_add-2: rgba(#f58e7d, 1);
$color_dvojka_add-2: rgba(#bd7cb5, 1);
$color_vltava_add-2: rgba(#abe1fa, 1);
$color_plus_add-2: rgba(#ffd400, 1);
$color_wave_add-2: rgba(#edde58, 1);
$color_d-dur_add-2: rgba(#e1b0c6, 1);
$color_jazz_add-2: rgba(#00bbce, 1);

$color_region_add-2: rgba(#96cf97, 1);
//$color_zelenavlna_add-2 zelena vlna nema definovanou variantu add-2



// CRo Add-3 colors
$color_corporate_add-3: rgba(#ca003a, 1);

$color_radiozurnal_add-3: rgba(#cd2549, 1);
$color_dvojka_add-3: rgba(#6a268b, 1);
$color_vltava_add-3: rgba(#0099cb, 1);
$color_plus_add-3: rgba(#e44e13, 1);
$color_wave_add-3: rgba(#b79100, 1);
$color_d-dur_add-3: rgba(#90135b, 1);
$color_jazz_add-3: rgba(#046888, 1);

$color_region_add-3: rgba(#009386, 1);
$color_zelenavlna_add-3: rgba(#008337, 1);



// External subject colors
$color_extsub_social_facebook: #3a5997;
$color_extsub_social_twitter: #00aced;
$color_extsub_social_googleplus: #dd4b39;
$color_extsub_social_youtube: #bb0000;
$color_extsub_social_linkedin: #007bb6;
$color_extsub_social_instagram: #e5679c;
