/* Fonts */

@include font-face("ArialRoundedMTBold", "../fonts/ArialRoundedMTBold", bold);
@include font-face("AcciusBuchRoundedDOT-Regu", "../fonts/AcciusBuchRoundedDOT-Regu");

$base-font-family: 'AcciusBuchRoundedDOT-Regu', Arial, sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 14px; // same as $em-base

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 3;
$base-z-index: 0;

// Colors
$blue: blue;
$black: #000;
$gray: #666;
$light-gray: #ddd;

// Font Colors
$base-font-color: $black;
$action-color: $black;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
