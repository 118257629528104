// See http://bourbon.io/docs/#settings

// when using postcss set to false
$prefix-for-webkit:    false;
$prefix-for-mozilla:   false;
$prefix-for-microsoft: false;
$prefix-for-opera:     false;
$prefix-for-spec:      false;

$output-bourbon-deprecation-warnings: true;
$em-base: 14;

@import "bourbon";
