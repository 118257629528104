@include respond-to('small-only') {
  .custom_hp-b_002b-wrapper {
    .child1 {
      float: right;
      width: 80px;
    }
  }
}

.custom_hp-b_002b-wrapper { // custom wrapper
  @include respond-to('medium') {
    @include span(345px at 295px);
  }
  @include respond-to('large') {
    @include span(510px at 450px);
  }
}
.pane-radiocustica-hp-b-002b-1 { // hlavni novinka
  .view-content {
    @include clearfix;
    @include respond-to('small-medium') {
      padding-top: 30px;
    }
  }
  .child1 {
    @include respond-to('small-medium') {
      float: right;
      margin-right: 20px;
      margin-top: -30px;
      width: 75px;
    }
    @include respond-to('medium') {
      @include span(130px at 0);
    }
    @include respond-to('large') {
      @include span(235px at 0);
    }
  }
  .child2 {
    @include respond-to('medium') {
      @include span(200px at 145px);
    }
    @include respond-to('large') {
      @include span(260px at 250px);
    }
  }
}
.pane-radiocustica-hp-b-004b-2 { // radioatelier vltava puvodne dalsi novinky b-002b-2
  margin-bottom: 1em;
  h2 {
    line-height: 1.1em;
    @include respond-to('small-medium') {
      margin-top: 50px;
    }
  }

  @include respond-to('medium') {
      @include span(315px at 30px);
    }
  @include respond-to('large') {
    @include span(430px at 80px);
  }
  padding-top: 30px;
  .child1 {
    //@include span(30%);
    //width: 30%;
    float: left;
    width: 30%;
    @include respond-to('medium') {
      @include span(70px at 0);
    }
    @include respond-to('large') {
      @include span(100px at 0);
    }
    @include respond-to('medium') {
      picture, img {
        position: relative;
        z-index: 9;
        visibility: visible;
        opacity: 1;
        transition: all .7s ease;
      }
    }
  }
  .child2 {
    //@include span(70% at 15%);
    float: left;
    width: 90%;
    margin-left: -20%;
    @include respond-to('medium') {
      @include span(280px at 35px);
    }
    @include respond-to('large') {
      @include span(360px at 65px);
    }
    // use negative margin-top with lineheight value on mobile
    // padding-top: 66px;
    .tag {
      @include respond-to('small-only') {
        float: right;
        margin-right: 20px;
      }
      @include respond-to('medium') {
        margin-left: 75px;
      }
      @include respond-to('large') {
        margin-left: 105px;
      }
    }
  }
  .slick-slide.slick-current {
    &:hover, &:focus {
      picture, img {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
.pane-radiocustica-hp-b-010a { // zaznamy poradu
  @include respond-to('small-medium') {
    margin-top: 40px;
  }
  @include respond-to('medium') {
    @include span(245px at 50px);
  }
  @include respond-to('large') {
    @include span(295px at 145px);
  }
  .views-row {
    max-width: 95%;
    overflow: hidden;
    margin-bottom: 1em;
    .views-field-title {
      margin-top: .3em;
    }
  }
}

//.pane-radiocustica-hp-b-002b-2 {
  .slick-arrows {
    color: $black;
    font-weight: 300;
    text-align: center;
    &:nth-child(n+3) {
      display: none;
    }
    span {
      @extend %arrow;
    }
    span.prevArrow {
      @extend %arrow-prev;
    }
    span.pipe {
      @extend %arrow-pipe;
    }
  }
//}

.pane-custom.pane-1 {
  .pane-content {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    @include respond-to('large') {
      justify-content: flex-start;
    }
    .fb_iframe_widget { // facebook box
      @include respond-to('large') {
        margin-top: 46px;
        margin-left: 85px;   
      }
    }
  }
}

.pane-radiocustica-hp-b-018b { // vltava promobox
  @include respond-to('small-medium') {
    margin-top: 40px;
  }
  @include respond-to('large') {
    @include span(525px at 435px);
  }
  clear: both;
  margin-top: 70px;
  margin-bottom: 0px;
  h2.views-field-title {
    display: inline-block;
    color: white;
    background: black;
    padding: .25em 1.3em .4em 1.3em;
    margin-bottom: .5em;
  }
}

.pane-radiocustica-hp-panel-pane-1 { // projekt pripravuji (hp carosel 2)
  @extend .pane-radiocustica-hp-b-018b;
  margin-bottom: 0;
  @include respond-to('small-medium') {
    .views-row {
      display: none;
    }
    .views-row-1, .views-row-2, .views-row-3 {
      display: block;
      margin-bottom: 1em;
      .field-content {
        position: relative;
        display: inline-block;
        span.onhover {
          display: table;
          visibility: hidden;
          opacity: 0;
          transition: all .7s ease;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0, .4);
          color: white;
          text-align: center;
          span {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      &:hover, &:focus {
        .field-content span.onhover {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .views-row-1 {
      max-width: 200px;
    }
    .views-row-2 {
      max-width: 220px;
    }
    .views-row-3 {
      max-width: 120px;
    }
  }
  .view-header h2 {
    text-transform: uppercase;
    font-size: em(14,14);
    text-decoration: underline;
    font-weight: 300;
  }
  .slick-list {
    @include respond-to('medium') {
      margin: 0 5%;
    }
    z-index: 1;
    .slick-track {
      width: 100%;
      /*@include respond-to('medium') {
        max-height: 130px;
        overflow-y: hidden;
      }*/
      .slick-slide {
        height: auto;
        //max-height: 130px;
        @include respond-to('small-medium') {
          float: none !important;
          margin-bottom: 5px;
          margin-top: 10px;
        }
        &.views-row-1,
        &.views-row-4,
        &.views-row-7 {
          //width: 30% !important;
          width: 140px !important;
        }
        &.views-row-2,
        &.views-row-5,
        &.views-row-8 {
          //width: 40% !important;
          width: 190px !important;
        }
        &.views-row-3,
        &.views-row-6,
        &.views-row-9 {
          //width: 20% !important;
          width: 95px !important;
        }
        .views-field-title {
          @include respond-to('medium') {
            padding-right: 10px;
            padding-left: 10px;
          }
          .field-content {
            position: relative;
            display: inline-block;
            span.onhover {
              display: table;
              visibility: hidden;
              opacity: 0;
              transition: all .7s ease;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: tint( $black, 40%);
              color: white;
              text-align: center;
              span {
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
          &:hover, &:focus {
            .field-content span.onhover {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .slick-prev {
    @extend %arrow;
    @extend %arrow-prev;
    position: absolute;
    top: 40px;
    left: -10px;
    @include respond-to('large') {
      background-size: 10px auto;
    }
  }
  .slick-next {
    @extend %arrow;
    position: absolute;
    top: 40px;
    right: -10px;
    @include respond-to('large') {
      background-size: 10px auto;
    }
  }
}

.pane-radiocustica-hp-b-013a {
  @extend .pane-radiocustica-hp-b-018b;
  margin-bottom: 0;
  margin-top: 20px;
}


.pane-radiocustica-hp-b-040 { // partneri hp
  @include respond-to('small-medium') {
    margin-top: 40px;
  }
  clear: both;
  padding-top: 3em;
  .pane-title {
    text-transform: uppercase;
    font-size: em(14,14);
    text-decoration: underline;
    font-weight: 300;
    @include respond-to('medium') {
      margin-left: -200px;
      text-align: center;
    }
  }
  .views-row {
    display: inline-block;
    max-width: 8em;
    margin: 1em;
  }
}




/* Generic  */
.tag {
  display: inline;
  padding: 0 2px;
  background-color: $black;
  color: white;
  margin-bottom: .3em;
  text-transform: uppercase;
  &:empty {
    display: none;
  }
}
