/* generic - assets mostly */
.not-logged-in {
  .wysiwyg-asset {
    .contextual-links-wrapper {
      display: none;
    }
  }
}
p.citation {
  font-size: em(16);
  font-style: italic;
  padding-left: 2em;
  margin: 1.3em 0;
  background: url(../images/citation.svg) no-repeat 0 0;
}
p.cteteTake {
  margin: 1.3em;
  display: flex;
  strong {
    max-width: 8em;
    padding-right: 1em;
  }
}
p.factbox {
  margin: 1.3em 0;
  color: $gray;
  font-size: em(15);
}
.asset-type-article {
  margin: 1.3em 0;
  h2 {
    font-size: em(15);
  }
  .field-name-field-image-primary {
    float: left;
    max-width: 85px;
    margin: 0 1em 1em 0;
  }
}
.photoswipe-gallery {
  margin: 1em 0;
  a.photoswipe {
    display: inline-block;
    max-width: 85px;
    margin: 0 1em 1em 0;
  }
}
.img-metadata {
  color: $gray;
  font-size: em(13);
}
.asset-type-image {
  max-width: 300px;
}
.asset-type-audio {
  margin: 1em 0;
}


/******************************
* ALBUM
******************************/
.node-type-album {
  article {
    @include clearfix;
  }
  .child1 {
    @include respond-to('medium') {
      @include span(280px at 40px);
    }
    @include respond-to('large') {
      @include span(278px at 105px);
    }
    h1 {
      font-size: em(20);
      font-weight: 700;
    }
  }
  .child2 {
    @include respond-to('medium') {
      @include span(300px at 340px);
    }
    @include respond-to('large') {
      @include span(560px at 400px);
    }
  }
  .child3 {
    padding-top: 30px;
    @include respond-to('large') {
      @include span(620px at 254px);
    }
  }
  .field-name-field-image-photos {
    background-color: #E6E6E6;
    text-align: center;
    padding: 45px 0;
    .field-label {
      @include hide-visually;
    }
    .slick-slider {
      max-width: 300px;
      margin: 0 auto;
    }
    .slick-prev {
      @extend %arrow;
      @extend %arrow-prev;
      position: absolute;
      top: 50%;
      left: -80px;
      background-size: 16px auto;
    }
    .slick-next {
      @extend %arrow;
      position: absolute;
      top: 50%;
      right: -80px;
      background-size: 16px auto;
    }
  }
  .field-name-field-entity-author-artwork,
  .field-name-field-entity-interpret,
  .field-name-field-entity-actor,
  .field-name-field-entity-production {
    .field-label, .field-items {
      display: inline-block;
    }
  }
  .meta-audio {
    margin-bottom: 1em;
    line-height: 1.25em;
    a {
      text-decoration: underline;
    }
  }
  .field-name-field-file-attachments {
    .file-icon {
      display: none;
    }
    a {
      text-decoration: underline;
      font-size: em(16);
    }
  }
}

/******************************
* ARTICLES
******************************/
.node-type-article {
  article {
    @include clearfix;
  }
  .child1 {
    @include respond-to('medium-large') {
      .sm2-progress .sm2-progress-track {
        background-size: 180px auto;
      }
      .sm2-bar-ui .sm2-controls-wrapper {
        min-width: 75%;
      }
    }
    @include respond-to('medium') {
      @include span(235px at 35px);
    }
    @include respond-to('large') {
      @include span(305px at 75px);
    }
  }
  .child2, .child3 {
    @include respond-to('medium') {
      @include span(320px at 320px);
    }
    @include respond-to('large') {
      @include span(560px at 400px);
    }
    h1 {
      @include respond-to('small-medium') {
        display: none;
      }
    }
  }
  .child3 {
    padding-top: 30px;
    @include respond-to('medium') {

    }
  }
  .meta1 {
    font-size: em(15);
    .field-label {
      display: none;
    }
    div {
      display: inline-block;
    }
  }
  h1 {
    font-size: em(20);
    margin: .4em 0 1em;
    .field-label {
      display: none;
    }
  }
  .field-name-field-entity-guest,
  .field-name-field-entity-author-main,
  .field-name-field-entity-actor,
  .field-name-field-entity-author-web,
  .field-name-field-entity-author-broadcast,
  .field-name-field-entity-interpret {
    .field-label, .field-items {
      display: inline-block;
    }
  }
  .field-name-body {
    margin: 1em 0;
  }
  .views-field-field-main-audio,
  .views-field-views-conditional {
    margin: 1.5em 0 3em;
  }
  .field-name-field-image-primary-title {
    .field-label {
      display: none;
    }
  }
  .field-name-field-image-primary-title,
  .field-name-field-entity-author-main {
    color: #797979;
  }
  .field-name-field-entity-related-articles,
  .related-content {
    position: relative;
    .field-label,
    h2 {
      font-size: em(15);
      text-decoration: underline;
      text-transform: uppercase;
    }

    a:link, a:active {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    @include respond-to('medium') {
      &:before {
        display: block;
        content: '';
        width: 250px;
        height: 50px;
        background: url(../images/player-progress-on.png) no-repeat 0 50%;
        background-image: url(../images/player-progress-on.svg);
        position: absolute;
        left: -265px;
        top: -15px;
      }
    }
  }
}

/******************************
* PROFILES & INSTITUTION
******************************/
.node-type-profile,
.node-type-institution {
  article {
    @include clearfix;
  }
  .child1  {
    @include respond-to('medium') {
      @include span(215px at 50px);
    }
    @include respond-to('large') {
      @include span(340px at 45px);
    }
  }
  .child2 {
    @include respond-to('medium') {
      @include span(320px at 320px);
    }
    @include respond-to('large') {
      @include span(500px at 400px);
    }
    h1 {
      @include respond-to('small-medium') {
        display: none;
      }
    }
  }
  h1 {
    font-size: em(20);
    margin: .4em 0 1em;
    .field-label {
      display: none;
    }
  }
  .field-name-field-image-primary {
    margin-top: 20px;
    .field-label {
      display: none;
    }
    @include respond-to('medium') {
      margin-top: 40px;
    }
    @include respond-to('large') {
      margin-top: 150px;
    }
  }
  .related-articles-title {
    margin-top: 1.8em;
    font-size: em(15);
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 400;
  }
  .view-display-id-author_articles {
    position: relative;
    a {
      text-decoration: underline;
    }
    @include respond-to('medium') {
      &:before {
        display: block;
        content: '';
        width: 250px;
        height: 50px;
        background: url(../images/player-progress-on.png) no-repeat 0 50%;
        background-image: url(../images/player-progress-on.svg);
        position: absolute;
        left: -240px;
        top: -45px;
      }
    }
  }
}

/******************************
* ABOUT PAGE
******************************/
.custom-about-page article {
  .child1 {
    display: none;
    @include respond-to('medium') {
      display: block;
      @include span(232px at 68px);
    }
    @include respond-to('large') {
      margin-top: 100px;
      @include span(340px at 45px);
    }
  }
  .child2 {
    position: relative;
    &:after {
        display: block;
        content: '';
        width: 150px;
        height: 150px;
        background: url(../images/page-about-ars.png) no-repeat 0 50%;
        position: absolute;
        left: -165px;
        bottom: 0px;
    }
    @include respond-to('medium') {
      @include span(320px at 320px);
      &:before {
        display: block;
        content: '';
        width: 150px;
        height: 50px;
        background: url(../images/page-about-bottom.png) no-repeat 0 50%;
        background-image: url(../images/page-about-bottom.svg);
        position: absolute;
        left: -125px;
        bottom: 180px;
      }
    }
    @include respond-to('large') {
      @include span(495px at 405px);
    }
  }
  .child3 {

  }
}

/******************************
* ART'S BIRTHDAY EVENTS
******************************/
.view-display-id-event_index {
  .views-row {
    margin: .5em 1em .5em 0;
    display: inline-block;
    @include respond-to('large') {
      display: inline;
      margin: 0 0 .5em 0;
    }
  }
  a {
    padding: 0 .2em 0 .8em;
    display: inline-block;
    :hover {
      background: black;
      color: white;
    }
  }
  .custom-active-trail a {
    background: black;
    color: white;
  }
}
.view-display-id-on_air_site_space {
  .view-header,
  .views-field-title {
    display: block;
    padding: .5em .8em;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .view-header {
    border-bottom: 1px solid white;
    margin-bottom: .4em;
  }
  .views-field-title {
    background: transparent;
    padding: 0;
    a {
      padding: 0 .8em;
      text-decoration: underline;
    }
    &.row-active a {
      background: black;
      color: white;
    }
  }
  .views-field-body {
    padding-top: .5em;
  }
  .attachment {
    .views-row {
      visibility: hidden;
      /*transition: all 500ms ease-in-out;
      transition: all 500ms cubic-bezier(0, 1, 0, 1);*/
      overflow: hidden;
      max-height: 0;
      &.row-nav-open {
        visibility: visible;
        max-height: 3000px;
        background: white;
      }
    }
  }
  .close-program-nav,
  .show-program-default {
    margin-top: 1em;
    display: block;
    //color: white;
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    background: url(../images/close-program-nav.svg) no-repeat 50% 50%;
    background-color: black;
    background-size: 25px;
  }
  .show-program-default {
    background-image: url(../images/show-program-default.svg);
    border-top: 1px solid white;
  }
  &.program-nav-open {
    background-color: transparent;
    .views-field-title a {
      //color: black;
    }
    .show-program-default, .view-footer {
      display: none;
    }
  }
}
&.arts-birthday-promo-active {
  .view-display-id-on_air_site_space {
    background-color: black;
    .view-header,
    .views-field-title {
      background: black;
      color: white;
      text-decoration: none;
      font-size: em(20);
      font-weight: 700;
    }
    .views-field-title {
      color: white;
      a {
        display: block;
        color: white;
        text-decoration: none;
      }
      &.row-active a {
        text-decoration: underline;
      }
    }
  }
}
.node-type-event {
  article {
    @include clearfix;
  }
  // not promonode
  .child0  {
    width: 100%;
    margin-bottom: 1em;
    @include respond-to('large') {
      @include span(110px at 0);
    }
  }
  .child1  {
    @include respond-to('medium') {
      @include span(280px at 18px);
    }
    @include respond-to('large') {
      @include span(340px at 112px);
    }
  }
  .child2 {
    @include respond-to('medium') {
      @include span(320px at 320px);
    }
    @include respond-to('large') {
      @include span(400px at 510px);
    }
  }
  // promonode
  &.arts-birthday-promo-active {
    .child1  {
      /*@include respond-to('medium') {
        @include span(280px at 18px);
      }*/
      @include respond-to('large') {
        @include span(360px at 18px);
      }
    }
    .child2 {
      /*@include respond-to('medium') {
        @include span(320px at 320px);
      }*/
      @include respond-to('large') {
        @include span(480px at 425px);
      }
      /*h1 {
        @include respond-to('small-medium') {
          display: none;
        }
      }*/
    }
  }
}

.view-display-id-partner_logos {
  margin-top: 2em;
  .view-header {
    padding-left: 150px;
    text-decoration: underline;
    margin: 1em 0;
  }
  .views-row {
    display: inline-block;
    margin: 1em 25px 1em 0;
    vertical-align: middle;
    max-width: 130px;
  }
}
