/**************************
 * Breakpoint
**************************/
@import "breakpoint";

//see https://github.com/at-import/breakpoint/wiki/Respond-To
@include breakpoint-set('no query fallbacks', true);
@include breakpoint-set('breakpoint to ems', true);

@include add-breakpoint('small', 480px); // mobile
@include add-breakpoint('medium', 640px); // tablet
@include add-breakpoint('large', 960px); // desktop

@include add-breakpoint('small-only', 0px 479px);
@include add-breakpoint('small-medium', 0px 639px);
@include add-breakpoint('medium-large', 640px 959px);



/**************************
 * Susy
**************************/
@import "susy";

// global settings see http://susydocs.oddbird.net/en/latest/settings/
$susy: (
  column-width: 96px,
  container: 960px,
  gutters: 0,
  global-box-sizing: border-box,
  math: static,
  output: 'isolate',
  debug: (
    image: show,
    output: overlay,
    toggle: bottom left,
  ),
);


