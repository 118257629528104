body.page-autori {
  .panel-1col {
     overflow: hidden; // avoid sroller blink when using pager nav
  }
}

.pane-radiocustica-autori-b-004f {
  .attachment-before {
    float: left;
    margin-top: -2.5em;
    @include respond-to('small-medium') {
      float: none;
      margin: 0 0 2em 0;
      ul.views-summary li {
        display: inline-block;
        width: 2em;
        margin-bottom: .7em;
      }
    }
  }
  .pager {
    color: $black;
    font-weight: 300;
    text-align: center;
    li {
      @extend %arrow;
      text-indent: inherit;
      background-size: 11px auto;
      margin: 0;
      &.pager-current {
        @extend %arrow-pipe;
        text-indent: -999em;
        background-size: auto 19px;
        margin: 0 2px;
      }
      &.pager-previous {
        @extend %arrow-prev;
        background-size: 11px auto;
      }
      a {
        display: inline-block;
        text-indent: -999em;
        width: 30px;
        height: 30px;
      }
    }
  }
  .view-content {
    @include clearfix;
    .pager { // top/first pager
      margin-left: -177px;
    }
  }
  .views-row {
    &.views-row-1, &.views-row-4  {  // 3n+1, 3n+4
      @include respond-to('medium') {
        @include span(300px at 340px);
      }
      @include respond-to('large') {
        @include span(565px at 390px);
      }
      .child1 {
        width: 100px;
        margin-left: 60px;
        @include respond-to('medium') {

          @include span(80px at 0);
        }
        @include respond-to('large') {
          @include span(220px at 0);
        }
      }
      .child2 {
        margin-left: 60px;
        @include respond-to('medium-large') {
          clear: left;
          margin-left: 0;
        }
        @include respond-to('large') {
          @include span(475px at 90px);
        }
      }
    }
    &.views-row-4 {
      @include respond-to('medium') {
        @include span(210px at 430px);
      }
      @include respond-to('large') {
        @include span(635px at 320px);
      }
      position: relative;
      z-index: 1;
      clear: left;
      padding-top: 20px;
      .child1 {
        @include respond-to('medium') {
          width: 100%;
        }
        @include respond-to('large') {
          @include span(300px at 0);
        }
      }
    }
    &.views-row-2, &.views-row-5 {
      @include respond-to('medium') {
        @include span(230px at 80px);
      }
      @include respond-to('large') {
        @include span(270px at 195px);
        padding-top: 85px;
      }
      .child1 {
        @include respond-to('small-medium') {
          width: 155px;
        }
        @include respond-to('medium-large') {
          width: 130px;
          margin-left: 70px;
        }
      }
    }
    &.views-row-5 {
      @include respond-to('medium-large') {
        padding-top: 20px;
      }
      @include respond-to('large') {
        padding-top: 145px;
      }
      .child1 {
        @include respond-to('medium-large') {
          width: 70px;
          margin-left: 30px;
        }
        @include respond-to('large') {
          @include span(160px at 0);
        }
      }
      .child2 {
        @include respond-to('medium-large') {
          padding-left: 55px;
        }
        @include respond-to('large') {
          clear: left;
        }
      }
    }
    &.views-row-3, &.views-row-6 {
      @include respond-to('small-medium') {
        margin-left: 20px;
      }
      @include respond-to('medium-large') {
        clear: left;
        @include span(500px at 140px);
        .child1 {
          @include span(180px at 0);
        }
        .child2 {
          @include span(310px at 190px);
        }
      }
      @include respond-to('large') {
        @include span(450px at 510px);
        margin-top: 12em;
        .child1 {
          @include span(140px at 115px);
          margin-top: 30px;
        }
      }
    }
    &.views-row-6 {
      @include respond-to('medium-large') {
        @include span(310px at 60px);
      }
      .child1 {
        @include respond-to('medium-large') {
          @include span(100px at 0);
        }
        @include respond-to('large') {
          @include span(260px at 0);
        }
      }
      .child2 {
        @include respond-to('medium-large') {
          @include span(240px at 110px);
        }
      }
    }

    @include respond-to('small-medium') {
      padding-top: 20px;
    }
    @include respond-to('medium') {
      picture, img {
        position: relative;
        z-index: 9;
        visibility: visible;
        opacity: 1;
        transition: all .7s ease;
      }
      &:hover, &:focus {
        picture, img {
          visibility: hidden;
          opacity: 0;
        }
      }
      &:nth-child(3n+1) {
        picture, img {
          z-index: 10;
        }
      }
    }
  }
  .views-exposed-widgets {
    display: none;
    @include respond-to('large') {
      display: block;
    }
    margin-left: 63%;
    margin-bottom: 1em;
    .views-exposed-widget {
      display: inline-block;
      float: none;
      padding: 0;
      vertical-align: top;
      input {
        margin: 0;
      }
    }
    input#edit-title {
      width: 10em;
      border: none;
      border-bottom: 1px solid #c7c7c7;
      margin: 0;
      padding: 0;
      line-height: 1em;
    }
    .views-submit-button {
      width: 3em;
      input {
        border: none;
        @include hide-text;
        text-indent: 300%;
        width: 20px;
        height: 37px;
        display: inline-block;
        background: url(../images/search.png) no-repeat 0 50%;
        background-image: url(../images/search.svg);
        background-size: 20px auto;
      }
    }
  }
}



/* DETAIL */
.node-type-profile {
  .l-grid--8-1 {
    width: 100%;
    @include clearfix;
    padding-bottom: 1em;
  }
  .pane-node-field-image-primary {
    @include respond-to('medium') {
      @include span(220px at 70px);
    }
    @include respond-to('large') {
      @include span(340px at 40px);
    }
  }
  .pane-node-body {
    @include respond-to('medium') {
      @include span(320px at 320px);
    }
    @include respond-to('large') {
      @include span(560px at 400px);
    }
  }
}
