body.page-aktuality {
  .panel-1col {
     overflow: hidden; // avoid sroller blink when using pager nav
  }
}

.pane-radiocustica-aktuality-b-004f {
  /*select {
    display: block;
    height: 10em;
    appearance: none;
    option {
      height: 1em;
      display: inline-block;
      float: left;
    }
  }*/

  .views-exposed-widgets { // stolen from autori
    display: none;
    @include respond-to('large') {
      display: block;
    }
    margin-left: 63%;
    margin-bottom: 1em;
    .views-exposed-widget {
      display: inline-block;
      float: none;
      padding: 0;
      vertical-align: top;
      input {
        margin: 0;
      }
    }
    input#edit-combine { // override from autori
      width: 10em;
      border: none;
      border-bottom: 1px solid #c7c7c7;
      margin: 0;
      padding: 0;
      line-height: 1em;
    }
    .views-widget-filter-field_admin_tags_tid {
      display: none;
    }
    .views-submit-button {
      width: 3em;
      input {
        border: none;
        @include hide-text;
        text-indent: 300%;
        width: 20px;
        height: 37px;
        display: inline-block;
        background: url(../images/search.png) no-repeat 0 50%;
        background-image: url(../images/search.svg);
        background-size: 20px auto;
      }
    }
  }
  .attachment-before {
    margin-bottom: 2em;
  }
  /*.attachment-before { // rejstrik
    float: left;
    margin-top: -2.5em;
    @include respond-to('small-medium') {
      float: none;
      margin: 0 0 2em 0;
      ul.views-summary li {
        display: inline-block;
        width: 2em;
        margin-bottom: .7em;
      }
    }
  }
  .
  .
  .
  */
  .views-row {
    position: relative;
    @include respond-to('small-medium') {
      padding-bottom: 15px;
      border-bottom: 1px solid black;
      margin-bottom: .5em;
    }
    .views-field-field-date-updated {
      @include respond-to('medium') {
        font-size: em(20);
        font-weight: 700;
        float: left;
        line-height: 1;
      }
    }
    .views-field-views-conditional {
      @include respond-to('small-medium') {
        display: none;
      }
      line-height: 1;
      float: right;
      text-transform: uppercase;
      @include respond-to('large') {
        position: absolute;
        top: 0;
        right: 0;
      }
      .field-content {
        background-color: black;
        padding: 2px 4px;
        margin-top: 1px;
        display: inline-block;
        color: white;
        a {
          color: white;
        }
      }
    }
    h2 {
      font-size: em(16);
      margin: .5em 0 0 0;
      @include respond-to('small-medium') {
        margin-top: .2em;
      }
    }
    .views-field-field-perex-short {
      width: 80%;
      @include respond-to('medium') {
        width: 100%;
        position: absolute;
        top: 25px;
        left: 0;
        max-height: 8em;
        overflow: hidden;
      }
      span.title {
        font-size: em(20);
        display: block;
        font-weight: 700;
        line-height: 1.2em;
      }
    }
    @include respond-to('medium') {
      .views-field-field-image-primary {
        img, picture {
          position: relative;
          z-index: 9;
          visibility: visible;
          opacity: 1;
          transition: all .7s ease;
        }
      }
      &:hover, &:focus {
        .views-field-field-image-primary {
          img, picture {
            visibility: hidden;
            opacity: 0;
          }

        }
      }
    }

    &:nth-child(6n+1)  {
      z-index: 1;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 110px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      @include respond-to('medium-large') {
        @include span(203px at 103px);
        .views-field-field-date-updated {
          margin-top: 60px;
        }
        .views-field-views-conditional {
          position: absolute;
          top: 31px;
          left: 75px;
        }
        .views-field-field-image-primary {
          max-width: 75px;
          position: absolute;
        }
        .views-field-field-perex-short {
          margin-top: 60px;
        }
      }
      @include respond-to('large') {
        @include span(346px at 200px);
      }
    }
    &:nth-child(6n+2)  {
      z-index: 2;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 80px;
          position: absolute;
          top: 0;
          right: 30px;
        }
      }
      @include respond-to('medium-large') {
        @include span(190px at 450px);
        .views-field-views-conditional {
          /*float: left;
          margin-left: -135px;*/
          position: absolute;
          left: -135px;
          top: 0;
        }
        .views-field-field-image-primary {
          max-width: 125px;
          margin-left: -135px;
        }
      }
      @include respond-to('large') {
        @include span(225px at 600px);
        margin-top: 40px;
        .views-field-field-image-primary {
          max-width: 160px;
        }
      }
    }
    &:nth-child(6n+3)  {
      z-index: 3;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 80px;
          position: absolute;
          bottom: 5px;
          right: 0;
        }
      }
      @include respond-to('medium-large') {
        @include span(210px at 430px);
        margin-top: 190px;
        .views-field-field-image-primary {
          max-width: 70px;
          margin-left: -80px;
        }
        .views-field-views-conditional {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      @include respond-to('large') {
        @include span(210px at 100px);
        margin-top: 270px;
      }
    }
    &:nth-child(6n+4)  {
      z-index: 4;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 80px;
          position: absolute;
          bottom: -7px;
          right: 20px;
        }
      }
      @include respond-to('medium-large') {
        @include span(270px at 370px);
        margin-top: 350px;
        .views-field-field-image-primary {
          max-width: 150px;
          margin-left: -160px;
          position: relative;
          top: -60px;
        }
        .views-field-views-conditional {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      @include respond-to('large') {
        @include span(250px at 618px);
        margin-top: 180px;
        .views-field-field-image-primary {
          max-width: 200px;
          margin-left: -100px;
        }
      }
    }
    &:nth-child(6n+5)  {
      z-index: 5;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 60px;
          position: absolute;
          top: 30px;
          right: 0px;
        }
      }
      @include respond-to('medium-large') {
        @include span(180px at 170px);
        margin-top: 430px;
        .views-field-field-image-primary {
          max-width: 80px;
          //margin-left: -90px;
          position: absolute;
          top: 0;
          left: -90px;
        }
        .views-field-views-conditional {
          position: absolute;
          top: 0;
          left: 30%;
        }
        .views-field-field-date-updated {
          position: absolute;
          top: 25px;
        }
        .views-field-field-perex-short {
          position: absolute;
          top: 50px;
        }
      }
      @include respond-to('large') {
        @include span(230px at 335px);
        margin-top: 355px;
      }
    }
    &:nth-child(6n+6)  {
      z-index: 6;
      @include respond-to('small-medium') {
        .views-field-field-image-primary {
          max-width: 110px;
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
      @include respond-to('medium-large') {
        @include span(240px at 380px);
        margin-top: 560px;
        .views-field-field-image-primary {
          max-width: 73px;
          //margin-left: -83px;
          position: absolute;
          bottom: 0;
          left: -83px;
        }
        .views-field-field-perex-short {
          position: static;
          max-height: inherit;
          overflow: visible;
        }
      }
      @include respond-to('large') {
        @include span(200px at 625px);
        margin-top: 340px;
      }
    }
  }
  .custom-filters {
    margin-bottom: 1.5em;
    @include respond-to('large') {
      @include span(120px at 0);
    }
    p {
      padding-left: .8em;
    }
    div {
      display: inline-block;
      @include respond-to('large') {
        display: block;
      }
      margin: .35em 0;
    }
    a {
      display: inline-block;
      text-decoration: none;
      padding: .1em .1em .1em .8em;
      min-width: 3.8em;
      &:hover, &:focus, &.active {
        background: black;
        color: white;
      }
    }
  }
  .view-empty {
    @include respond-to('large') {
      @include span(700px at 200px);
    }
  }
  .custom-filters {
    display: none;
  }
  .view-content {
    .custom-filters {
      &:first-child {
        display: block;
      }
      .views-exposed-form {
        margin-top: 2em;
      }
      .views-widget-filter-combine,
      .views-submit-button {
        display: none;
      }
      .views-widget-filter-field_admin_tags_tid {
        display: block;
      }
    }
  }
  .view-content {
    @include clearfix;
    > .js-wrapper {
      margin-bottom: 20px;
      &:nth-child(3n+2) {
        //background-color: yellow;
        .views-row {
          &:nth-child(6n+1)  {
            z-index: 1;
            @include respond-to('large') {
              @include span(280px at 490px);
            }
          }
          &:nth-child(6n+2)  {
            z-index: 2;
            @include respond-to('large') {
              @include span(243px at 200px);
              margin-top: 55px;
              .views-field-field-image-primary {
                max-width: 183px;
              }
            }
          }
          &:nth-child(6n+3)  {
            z-index: 3;
            @include respond-to('large') {
              @include span(250px at 422px);
              margin-top: 215px;
              .views-field-field-image-primary {
                max-width: 150px;
              }
            }
          }
          &:nth-child(6n+4)  {
            z-index: 4;
            @include respond-to('large') {
              @include span(230px at 140px);
              margin-top: 240px;
              .views-field-field-image-primary {
                max-width: 180px;
                margin-left: 0;
              }
            }
          }
          &:nth-child(6n+5)  {
            z-index: 5;
            @include respond-to('large') {
              @include span(220px at 685px);
              margin-top: 275px;
              .views-field-field-image-primary {
                max-width: 95px;
                margin-left: -25px;
              }
            }
          }
          &:nth-child(6n+6)  {
            z-index: 6;
            @include respond-to('large') {
              @include span(215px at 373px);
              margin-top: 400px;
            }
          }
        }
      }
      &:nth-child(3n+3) {
        //background-color: blue;
      }
    }
  }
  .js-wrapper {
    @include clearfix;
  }
  .pager {
    width: 100%;
    text-align: center;
    clear: both;
    li a {
      display: inline-block;
      margin: 0 auto;
      background: url(../images/pager.png) no-repeat 50% 50%;
      background-image: url(../images/pager.svg);
      width: 242px;
      height: 44px;
      line-height: 44px;
      color: white;
      padding-left: 50px;
    }
  }
}

