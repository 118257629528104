/**
* Only the main layout, other in part-* files
*

/
/*html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}
*/

html {
  scroll-behavior: smooth;
}

.clearfix {
  @include clearfix;
}

#page-wrapper,
#branding {
  @include container(85%);
  @include respond-to('medium') {
    @include container(640px);
  }
  @include respond-to('large') {
    @include container;
  }
}
#branding {
  /*position: fixed;
  top: 0;*/
  background-color: white;
  z-index: 1;
  z-index: 21;
  //height: 50px;
  // fill the empty space behind
  /*&:after {
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: -1;
    background-color: white;
    display: block;
    content: "";
    top: 0;
  }*/
}

#header {
  //height: 158px; // was 118
  z-index: 20;
  z-index: 9999999;
  background: white;
  //position: fixed;
  width: 100%;
  //top: 50px;
  @include respond-to('small-medium') {
    //margin: 0 -7.5%;
    margin: 0;
    width: 100% !important;
    height: auto;
    background-color: black;
    //height: 118px;
    padding-bottom: 20px;
  }
  //@include respond-to('medium') {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
  //}
}
#branding, #header, #page-wrapper {
  @include respond-to('small-medium') {
    @include container(100%);
  }
}
main, #logo {
  @include respond-to('small-medium') {
    @include container(85%);
  }
}
#logo {
  margin: 0 auto;
  //height: 78px;
  //position: fixed;
  z-index: 0;
  //top: 50px;
  //left: 0;
  @include respond-to('medium') {
    //top: 70px;
    //left: 50%;
    //margin-left: -154px; // half of logo width or use transform-x
    text-align: center;
  }
  //transition: all .7s ease-in-out;
  transition: all cubic-bezier(0, 1, 0, 1);
  a.logo {
    @include hide-text;
    display: inline-block;
    width: 308px;
    height: 58px;
    @include respond-to('medium') {
      height: 78px;
    }
    margin: 0 auto;
    background: url(../images/logo-sprite.svg) no-repeat 0 0;
    @include respond-to('small-medium') {
      background-position: -50px 0;
    }
    position: relative;
  }
}
.main-menu--radiocustica {
  z-index: 999999;
  @include respond-to('medium') {
    position: -webkit-sticky;
    position: sticky;
    top: 77px;
  }
  width: 100%;
  @include respond-to('medium') {
    //#margin-left: calc(-50% + 480px);
    padding: 10px 0;
    background: white;
    //position: fixed;
  }
  //transition: all .7s linear;
  .content {
    /*margin: 0 auto;
    text-align: center;*/
    //background: linear-gradient(to bottom, transparent 47%, black 47%, black 53%, transparent 53%);
  }
  ul.menu {
    list-style-type: none;
    li {
      margin: 0 1em 0 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      @include respond-to('medium-large') {
        margin-right: .5em;
      }
       &.first {
        //margin-left: 340px;
        @include respond-to('medium') {
          &:before {
            content: "";
            display: inline-block;
            width: 40px;
            height: 20px;
            background: linear-gradient(to bottom, transparent 10px, black 10px, black 11px, transparent 11px);
            margin-right: 20px;
            vertical-align: middle;
          }
        }
        @include respond-to('large') {
          &:before {
            width: 180px;
          }
        }
      }
      a {
        padding: 3px;
        background-color: transparent;
        color: black;
        text-transform: uppercase;
        &.active,
        &.active-trail,
        &:hover,
        &:focus {
          background-color: black;
          color: white;
        }
      }
    }
  }
}

main {
  //margin-top: 228px; // was 178
  margin-top: 30px;
}

.front/*,
.arts-birthday-promo-active*/ {
  main {
    //margin-top: 178px;
  }
  #header {
    //height: 118px;
  }
  @include respond-to('medium') {
    #block-menu-menu-main-menu-radiocustica--2 {
      display: none;
    }
  }
  #block-menu-menu-main-menu-radiocustica {
    clear: both;
    display: none;
    @include respond-to('medium') {
      display: block;
      //#bottom: 10px;
      //top: 555px;
      height: 20px;
    }
    @include respond-to('large') {
      //top: 540px;
    }
  }
}
.front.arts-birthday-promo-active {
  @include respond-to('small-medium') {
    main {
      margin-top: -5px;
    }
  }
  #logo {
    @include respond-to('medium') {
      display: flex;
      justify-content: center;
    }
    a.logo {
      margin: 0;
      width: 277px;
    }
  }
  .promo-feather {
    background: url(../images/promo-feather.svg) no-repeat 0 50%;
    background-size: auto 78px;
    margin: 0;
    color: white;
    margin-left: -30px;
    line-height: 1.1em;
    text-transform: uppercase;
    //@include respond-to('medium') {
        height: 78px;
        min-width: 300px;
    //}
    a {
      color: white;
      text-decoration: underline;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }
  .mobile-only {
    .promo-feather {
      margin-left: -20px;
      text-align: center;
      width: 194px;
      min-width: 194px;
      background-position: 100% 50%;
      span {
        margin-left: -10px;
      }
    }
  }
}


body.scrolled {
  #header {
    //height: 108px; // was 68
    /*&:after {
      content: "";
      display: block;
      height: 45px;
      position: fixed;
      background: white;
      width: 100%;
      z-index: -1;
    }*/
  }
  &.front #header {
    //height: 68px;
  }
  #logo {
    //top: 50px;
    height: 68px;
    a.logo {
      background-position: 0 100%;
      height: 45px;
    }
  }
}
body.not-front {
  #block-menu-menu-main-menu-radiocustica {
    //top: 148px;
    //padding-top: 30px;
  }
  &.scrolled {
    #block-menu-menu-main-menu-radiocustica {
      top: -20px !important;
      padding-top: 10px;
    }
  }
}

body.not-front.arts-birthday-promo-active {
  #header {
    @include respond-to('medium') {
      //background-color: transparent;
    }
  }
  /*#block-menu-menu-main-menu-radiocustica {
    @include respond-to('medium') {
      //top: 368px;
      //top: -280px !important; // fakt promobox filler height
      padding-top: 20px;
      position: sticky;
      //position: absolute;
    }
  }*/
  main {
    @include respond-to('medium') {
      //margin-top: 508px;
    }
  }
}

hr.divider {
  border: none;
  background: none;
  color: none;
  width: 100%;
  clear: both;
}
@-webkit-keyframes logo-change {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
}
