/*
 * @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 */

// Make sure the charset is set appropriately
@charset "UTF-8";

@import "normalize";  // use reboot.scss instead when launched and mature
@import "init/bourbon-init";

@import "init/susy-init";

@import "variables";
@import "mixins-functions-extensions";

@import "layout";
@import "typography";
@import "elements";
@import "audio";
@import "responsivemenublock";
//@import "part-header";
//@import "part-footer";

@import "page-hp";
@import "page-autori";
@import "page-artsbirthday";
@import "page-cdkompilace";
//@import "page-about";
@import "page-archiv";
@import "nodes";


@import "slick";
@import "admin_navbar_webeditor";
@import "promobox";


//@import "patternlab/*"

