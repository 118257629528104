// backported from bourbon 4x
// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return ($pxval / $base) * 1em;
}

@mixin hide-visually($toggle: "hide") {
  @if not index("hide" "unhide", $toggle) {
    @error "`#{$toggle}` is not a valid value for the `$toggle` argument in " +
           "the `hide-visually` mixin. Must be either `hide` or `unhide`.";
  } @else if $toggle == "hide" {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  } @else if $toggle == "unhide" {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
  }
}



/* Extensions - reusable */

%arrow {
  cursor: pointer;
  z-index: 9000;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-indent: -999em;
  outline: none !important;
  border: none;
  color: transparent;
  text-shadow: 0 0 0 gray;
  vertical-align: middle;
  background: url(../images/arrow-right.png) no-repeat 0 0;
  background-image: url(../images/arrow-right.svg);
  background-size: 15px auto;
}
%arrow-prev {
  background-image: url(../images/arrow-left.png);
  background-image: url(../images/arrow-left.svg);
  background-size: 15px auto;
  background-position: 100% 0;
}
%arrow-pipe {
  margin: 0 10px;
  background-image: url(../images/pipe.png);
  background-image: url(../images/pipe.svg);
  background-size: auto 22px;
  background-position: 50% 0;
  cursor: none;
  pointer-events: none;
  width: 3px;
}
%temporary {
  &:before {
    content: "Dočasné: schovat, upřesnit, upravit...";
    font-weight: 700;
  }
  background: rgba(255,255,80, .2);
  border: 1px dotted rgba(255,255,80, .8);
  margin: 1em 0;
}

