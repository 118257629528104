html, body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
img, picture {
  width: auto;
  max-width: 100%;
  height: auto;
}
iframe {
  max-width: 100%;
}
form {
  padding: 0;
  background: none;
}

ul {
  &.tabs.primary {
    list-style: none;
    padding: 5px;
    border: black 1px dotted;
    margin: 1em 0;
    li {
      display: inline-block;
      margin-right: 1.5em;
    }
  }
}

.element-invisible {
  @include hide-visually();
}
.mobile-only {
  @include respond-to('medium') {
    display: none;
  }
}
.mobile-not {
  @include respond-to('small-medium') {
    display: none;
  }
}
