//.pane-radiocustica-arts-birthday-b-xxx { // promo box
.view-display-id-b_xxx {
  //display: none;
  //margin-top: -60px;
  margin-top: -30px;
  margin-bottom: 15px;
  @include respond-to('medium') {
    display: block;
    /*position: absolute;
    top: 50px;*/
    height: 356px;
    overflow: hidden;
    width: 640px;
    margin-bottom: 30px;
  }
  @include respond-to('large') {
    /*display: block;
    position: absolute;
    //top: -438px; // element main margin-top from layout.scss
    top: 50px;
    height: 356px;
    overflow: hidden;*/
    width: 960px;
    //margin-top: -113px;
    //@include span(360px at 20px);
    /*.view-content {
      position: relative;
      .views-field-field-event-start,
      .views-field-field-internal-notice {
        position: absolute;
        top: 150px;
        width: 100%;
        text-align: center;
        font-size: em(55);
        text-shadow: -3px -2px 1px rgba(250,250,250,0.85), 3px 3px 4px rgba(250,250,250,.5);
        font-weight: bold;
      }
      .views-field-field-event-start {
        top: 220px;
        right: 10%;
        text-align: right;
        font-size: em(25);
      }
    }*/
  }
}
.pane-radiocustica-taxonomy-arts-birthday {  // text
  display: none;
  @include respond-to('large') {
    display: block;
    @include span(535px at 425px);
  }
}

.pane-radiocustica-arts-birthday-a-008a-1 { // vsechny rocniky
  clear: both;
  .views-row {
    @include respond-to('medium') {
      float: left;
      width: 49%;
    }
    margin-bottom: .5em;
    text-align: center;
    position: relative;
    @include respond-to('medium') {
      &:nth-child(2n+2) {
        float: right;
      }
    }

    .views-field-field-image-primary {
      font-size: 0;
      line-height: 0;
    }
    .views-field-field-event-start {
      line-height: 1.5em;
      position: absolute;
      bottom: 0;
      background-color: rgba($black, .5);
      width: 100%;
      height: 33.3%;
      .field-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        a {
          color: white;
        }
      }
    }
  }
}
.pane-radiocustica-arts-birthday-a-008a-2 {
  @extend .pane-radiocustica-arts-birthday-a-008a-1;
}
