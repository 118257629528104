$player_gray: #efedf2;
$color_decoration_main: red;
 $color_text_paragraph: gray;

.views-field-field-main-audio,
.views-field-views-conditional,
.file-audio {
  /*padding: 0.625rem 1rem;
  border: 1px solid #d6d6d8;*/

  /*@include breakpoint($breakpoint_desktop) {
    margin-left: 4.375rem;
    padding: 1.25rem 0.625rem 1.25rem 15px;
  }*/
  .sm2-bar-ui {
    display: block;
    max-width: unset;
    font-size: em(16);
    min-width: unset;
    transition: 1s;
    max-width: 21em;
  }

  .sm2-playlist {
    display: none;
    float: left;
    width: 100%;
    color: black;
    /*ul {
      width: 100%;
      font-size: em(14);
      li {
        text-align: left;
        font-size: 100%;
        .load-error {
          display: none;
        }
      }
      .filename {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        max-width: calc(100% - 7em);
      }

      .title-end {
        float:left;
        width: 6.5em;
        margin: 0 0 0 .2em;
        text-align: left;
      }
    }*/
  }
/*
  .sm2-bar-ui .sm2-menu {
    border: 1px solid red;
  }
*/
  .sm2-bar-ui .sm2-inline-element {
    border: none;
    //display: block;
    //float: left;
    overflow: hidden;
    /*width: 19%;
    min-width: 19%;
    max-width: 19%;*/
    display: inline-block;
    padding: 0;
    margin: 0;
    height: em(50);
    &:hover {
      background: none;
    }
    .sm2-button-bd {
      width: 100%;
      height: 100%;
      max-width: 3.4em;
      //max-height: 3.5em;
    }
  }

  .sm2-bar-ui .bd,
  .sm2-bar-ui .sm2-extra-controls {
    background: none;
    color: black;
    width: 100%;
  }

  .sm2-bar-ui .sm2-controls-wrapper {
    //width: 78%;
    min-width: 78%;
    //max-width: 100%;
    //float: right;
    width: auto;
    float: none;
    display: inline-block;
    margin-left: 10px;
    @include respond-to('medium-large') {
      margin-left: 0;
    }


    /*min-width: calc(100% - 5.3em);
    float: left;
    padding: 0;
    font-size:em(14);
    margin: .3em 1em;*/
  }

  .sm2-progress {
    //display: none;
    //float: left;
    //overflow: hidden;
    width: 100%;
    clear: both;

    .sm2-row {
      display: block;
    }

    .sm2-progress-bd {
      padding: 0;
    }
  }
  .sm2-time-wrapper {
    display: none;
  }
  .sm2-bar-ui .sm2-time-wrapper {
    //font-size: em(11);
    > div {
      padding: .15em;
      background-color: black;
      color: white;
      display: inline;
      position: absolute;
      bottom: 0;
      &.delitko {
        display: none;
      }
    }
    .sm2-inline-time {
      //left: 23%;
      left: 4.7em;
    }
    .sm2-inline-duration {
      right: 0%;
    }
  }

  .sm2-progress .sm2-progress-track,
  .sm2-progress .sm2-progress-ball,
  .sm2-progress .sm2-progress-bar {
    border-radius: 0;
    height: em(50);
    background-size: 100% auto;
  }
  .sm2-progress-ball {
    /*height: .065em;
    width: 0;*/
    display: none;
  }
  /*.playing,
  .paused {*/
    .sm2-progress .sm2-progress-track {
      background: transparent url(../images/player-desktop_1-off.png) no-repeat 0 50%;
      background-image: url(../images/player-desktop_1-off.svg);
      background-size: 220px auto;
      @include respond-to('medium-large') {
        background-size: 210px auto;
      }
    }
    .sm2-progress .sm2-progress-bar {
      background: transparent url(../images/player-desktop_1.png) no-repeat 0 50%;
      background-image: url(../images/player-desktop_1.svg);
      background-size: 220px auto;
      @include respond-to('medium-large') {
        background-size: 210px auto;
      }
    }
  /*}*/
  .play-pause,
  .play-pause:hover {
    background-image: url(../images/player-play.png);
    background-image: url(../images/player-play.svg);
    background-position: 0;
    background-size: 75% auto;
    background-size: 100% auto;
    @include respond-to('medium-large') {
      background-size: 75% auto;
    }
  }
  .playing .play-pause {
    background-image: url(../images/player-pause.png);
    background-image: url(../images/player-pause.svg);
    background-position: 0;
    background-size: 100% auto;
    @include respond-to('medium-large') {
      background-size: 75% auto;
    }
  }
  .sm2-bar-ui .sm2-volume {
    display: none;
  }
  .playing,
  .paused {
    .sm2-progress,
    .sm2-time-wrapper {
      display: block;
    }
    /*&.sm2-bar-ui {
      height: 6.625rem;
      transition: height 1s;
    }*/
  }
}
.views-row:nth-child(7n+2),
.field-name-body .asset-type-audio:nth-child(7n+2) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_2-off.png);
    background-image: url(../images/player-desktop_2-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_2.png);
    background-image: url(../images/player-desktop_2.svg);
  }
}
.views-row:nth-child(7n+3),
.field-name-body .asset-type-audio:nth-child(7n+3) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_3-off.png);
    background-image: url(../images/player-desktop_3-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_3.png);
    background-image: url(../images/player-desktop_3.svg);
  }
}
.views-row:nth-child(7n+4),
.field-name-body .asset-type-audio:nth-child(7n+4) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_4-off.png);
    background-image: url(../images/player-desktop_4-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_4.png);
    background-image: url(../images/player-desktop_4.svg);
  }
}
.views-row:nth-child(7n+5),
.field-name-body .asset-type-audio:nth-child(7n+5) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_5-off.png);
    background-image: url(../images/player-desktop_5-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_5.png);
    background-image: url(../images/player-desktop_5.svg);
  }
}
.views-row:nth-child(7n+6),
.field-name-body .asset-type-audio:nth-child(7n+6) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_6-off.png);
    background-image: url(../images/player-desktop_6-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_6.png);
    background-image: url(../images/player-desktop_6.svg);
  }
}
.views-row:nth-child(7n+7),
.field-name-body .asset-type-audio:nth-child(7n+7) {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_7-off.png);
    background-image: url(../images/player-desktop_7-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_7.png);
    background-image: url(../images/player-desktop_7.svg);
  }
}

.player-skins-2 {
  .sm2-progress .sm2-progress-track {
    background-image: url(../images/player-desktop_8-off.png);
    background-image: url(../images/player-desktop_8-off.svg);
  }
  .sm2-progress .sm2-progress-bar {
    background-image: url(../images/player-desktop_8-off.png);
    background-image: url(../images/player-desktop_8-off.svg);
  }
  .views-row:nth-child(7n+2) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_9-off.png);
      background-image: url(../images/player-desktop_9-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_9.png);
      background-image: url(../images/player-desktop_9.svg);
    }
  }
  .views-row:nth-child(7n+3) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_10-off.png);
      background-image: url(../images/player-desktop_10-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_10.png);
      background-image: url(../images/player-desktop_10.svg);
    }
  }
  .views-row:nth-child(7n+4) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_11-off.png);
      background-image: url(../images/player-desktop_11-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_11.png);
      background-image: url(../images/player-desktop_11.svg);
    }
  }
  .views-row:nth-child(7n+5) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_12-off.png);
      background-image: url(../images/player-desktop_12-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_12.png);
      background-image: url(../images/player-desktop_12.svg);
    }
    .views-row:nth-child(7n+6) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_13-off.png);
      background-image: url(../images/player-desktop_13-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_13.png);
      background-image: url(../images/player-desktop_13.svg);
    }
  }
  .views-row:nth-child(7n+7) {
    .sm2-progress .sm2-progress-track {
      background-image: url(../images/player-desktop_14-off.png);
      background-image: url(../images/player-desktop_14-off.svg);
    }
    .sm2-progress .sm2-progress-bar {
      background-image: url(../images/player-desktop_14.png);
      background-image: url(../images/player-desktop_14.svg);
    }
  }
  }
}
