//
// Custom Mixins
//



// link mixin with default link styles set as project default
@mixin link-style ($color: $color_text_link, $decor: none, $color-hover: $color_text_link, $decor-hover: underline) {
	&:link, &:visited {
		color: $color;
		text-decoration: $decor;
	}
	&:hover, &:active {
		color: $color-hover;
		text-decoration: $decor-hover;
	}
	&:focus {
		outline: none;
	}
}

@mixin size3-2($width) {
	width: $width;
	height: ($width * 2)/3;
}


@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
}

@mixin no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


	/*> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}*/


/*

// General styles for arrows usually used with slideshows & carousels
@mixin arrow($bg-img, $width, $height) {
  text-indent: -9999px;
  background: $bg-img;
  width: $width;
  height: $height;
  position: absolute;
  z-index: 1000;
}

// General styles for slideshow pager (counter)
@mixin counter($bg-img, $width, $height, $margin) {
  background: $bg-img;
  width: $width;
  height: $height;
  float: left;
  margin: $margin;
  border-radius: 0;
  box-shadow: none;
}

// Carousel
@mixin carousel($clip-width: 620px) {
  .jcarousel-skin-default .jcarousel-container-horizontal {
	height: auto;
	width: 100%;
	background: $title-bg;
	padding: 1em;  
  }
  
  .jcarousel-skin-default .jcarousel-clip-horizontal {
	width: $clip-width;
	overflow: hidden;
	margin-left: -.5em;
  }
  
  .jcarousel-skin-default .jcarousel-item {
	padding: 0;
	width: 176px;
	height: auto;
	overflow: hidden;
	border: none;
	list-style: none;
	background: none;
  }
  
  .jcarousel-skin-default .jcarousel-item-horizontal {
	margin: 0 4px;
  }
	
  .jcarousel-skin-default .jcarousel-prev-horizontal {
	@include arrow($arrow-left, em(55px), em(57px));
	top: em(55px);
	left: em(-11px);
  }
  
  .jcarousel-skin-default .jcarousel-next-horizontal {
	@include arrow($arrow-right, em(55px), em(57px));
	top: em(55px);
	right: em(-11px);
  }
}

// Top News
@mixin top-news() {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  float: none;
}




// ---------------------------------------------------

@mixin vertical-margin ($top-margin: $vertical-top-margin, $bot-margin: $vertical-bottom-margin){
  
  margin-top: $top-margin;
  margin-bottom: $bot-margin;
}

@mixin vertical-padding ($top-padding: $vertical-top-padding, $bot-padding: $vertical-bottom-padding){
  
  padding-top: $top-padding;
  padding-bottom: $bot-padding;
}

*/
