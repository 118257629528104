
.pane-radiocustica-cd-kompilace-b-008f {
  @include respond-to('medium') {
    @include span(540px at 50px);
  }
  @include respond-to('large') {
    @include span(780px at 135px);
  }
  .view-header {
    margin-bottom: 1.5em;
    @include respond-to('medium') {
      margin-left: 167px;
    }
    @include respond-to('large') {
      margin-left: 265px;
    }
  }
  .views-row {
    @include respond-to('small-medium') {
      float: none !important;
      margin-bottom: 25px;
      margin-top: 10px;
    }
    .views-field {
      margin: 0 auto;
    }
    @include respond-to('medium') {
      &.views-row-1, &.views-row-4 {
        .views-field {
          width: 75% !important;
        }
      }
      &.views-row-3 {
        .views-field {
          width: 50% !important;
        }
      }
    }
    .views-field-nothing {
      @include respond-to('medium') {
        padding: 20px 10px;
      }
      .field-content {
        display: block;
        position: relative;
        overflow: hidden;
        line-height: 0;
      }
      .album-text {
        position: absolute;
        width: 100%;
        height: 33.3%;
        background-color: rgba($black, .6);
        color: white;
        line-height: 1.5em;
        a {
          color: white;
        }
        bottom: 0;
        @include respond-to('large') {
          height: 100%;
          bottom: -100%;
          transition: bottom .7s ease;
        }
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          p {
            text-align: center;
          }
          span {
            display: inline-block;
            img.file-icon,
            span.file-size {
              display: none;
            }
          }
        }
      }
    }
    &:hover, &:focus {
      .field-content .album-text {
        @include respond-to('large') {
          bottom: 0;
        }
      }
    }
  }
  .slick-prev {
    @extend %arrow;
    @extend %arrow-prev;
    position: absolute;
    top: 20px;
    left: -35px;
    @include respond-to('large') {
      background-size: 10px auto;
    }
  }
  .slick-next {
    @extend %arrow;
    position: absolute;
    top: 20px;
    right: -35px;
    @include respond-to('large') {
      background-size: 10px auto;
    }
  }
}

