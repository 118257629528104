// 
// Menu for webeditors
// 

$file_icon_triangle_south_colored-white: url('/sites/all/themes/custom/e7/images/icon/ico_arr_more_colored-white.svg');
$file_icon_home: url('/sites/all/themes/custom/e7/images/icon/ico_icon_home.svg');
$menu-background: #0074bd;
$menu_breakpoint: 600px;

.admin-top-menu {

  .menu_show {
    display: block !important;
    transition: all 0.5s;
  }
	.menu {
		display: flex;
		justify-content: space-between;
		background-color: $menu-background;
		flex-wrap: wrap;
		background-image: none;
		@media only screen and (min-width: $menu_breakpoint) {
			flex-wrap: nowrap;
		}
      
      .inner-wrap {
        @media only screen and (max-width: $menu_breakpoint) {
          display: none;
        }
      }

      &__hamburger {
        display: none;
        background-color: $color_background-0;
        float: right;
        clear: right;
        &:hover {
          background-color: darken($color_background-0, 10%);
        }
        img {
          width: 20px;
          position: absolute;
          top: 10px;
          right: 20px;
          display: none;
          @media only screen and (max-width: $menu_breakpoint) {
            display: block;
          }
        }
        @media only screen and (max-width: $menu_breakpoint) {
          background-color: $color_background-0;
          height: 40px;
          width: 50%;
          display: inline-flex;
          float: right;
          text-align: right;
          cursor: pointer
        }
      }

		// link na uvodni stranu
		&__logo {
			background-color: $color_background-0;
			width: 50%;
            float: left;
          z-index: 10;
			@media only screen and (min-width: $menu_breakpoint) {
				width: auto;
              display: inline-flex;
			}
			&:hover {
				background-color: darken($color_background-0, 10%);
			}
			& > a {
				width: calc(100% - 2rem);
				padding: 1.25rem 1rem;
				display: inline-block;
			}
			img {
				width: 11px;
				position: absolute;
				top: 12px;
				left: 10px;
			}
		}

		// dashboard
		&__dashboard {
          clear: both;
			@media only screen and (min-width: $menu_breakpoint) {
				min-height: 35px;
              clear: none;
			}
			&:hover {
				background-color: darken($menu-background, 10%);
			}
		}

		&__dashboard-ico {
			padding: .75rem;
			display: flex;
			width: 100%;
			&:after {
				content: '';
				width: 16px;
				height: 16px;
				background-repeat: no-repeat;
				background-position: 4px 2px;
				background-image: $file_icon_home;
				background-size: 12px;
			}
		}

		// polozka menu
		&__link {
			display: flex;
			font-size: 0.8125rem;
			font-weight: 400;
			font-family: 'Open Sans', 'Segoe UI', 'Helvetica', sans-serif;
			padding: .3rem 1rem;
			width: 100%;
			color: $color_background-0;
			transition: background-color 250ms ease;
			white-space: nowrap;
			@media only screen and (min-width: $menu_breakpoint) {
				padding: .65rem 1rem;
			}
			&:hover {
				text-decoration: none;
				cursor: pointer;
				background-color: darken($menu-background, 10%);
			}
		}

		// polozka menu obsahujiuci submenu
		&--dropdown {
			padding-right: 0;
		}

		// nazev prihlaseneho uzivatele
		&__user {
			flex-grow: 1;
			@media only screen and (min-width: $menu_breakpoint) {
				flex-grow: auto;
			}
			.menu__link {
				background-color: #333333;
				&:hover {
					background-color: #525252;
				}
			}
		}

		// odhlaseni
		&__logout {
			&:after {
				content: '';
			}
			.menu__link {
				background: #c52b2b;
				&:hover {
					background: #8e1d1d;
				}
			}
		}

		// wrapper podmenu
		&__dropdown-wrap {
			padding-right: 18px !important;
			background-color: $menu-background;
			&:hover {
				background-color: darken($menu-background, 10%);
			}
			& > a {
				position: relative;
				color: $color_background-0;
				&:after {
					content: '';
					position: absolute;
					right: -18px;
					top: 10px;
					width: 20px;
					height: 10px;
					display: block;
					background-repeat: no-repeat;
					background-position: center 4px;
					background-image: $file_icon_triangle_south_colored-white;
					background-size: 8px;
					@media only screen and (min-width: $menu_breakpoint) {
						top: 12px;
					}
				}
			}
		}

		// obsah podmenu
		&__dropdown {
			visibility: hidden;
			opacity: 0;
			min-width: 9rem;
			position: absolute;
			margin-top: .5rem;
          width: 100%;
          left: -20px;
          top: 20px;
			display: none;
			background-color: $color_background-8;
			@media only screen and (min-width: $menu_breakpoint) {
				background-color: $menu-background;
				top: 30px;
				left: -19px;
			}
			li {
				clear: both;
				width: 100%;
				a {
					color: $color_background-0;
					width: 100%;
					display: flex;
				}
			}
		}

		&__wrap {
			list-style: none;
			margin: 0;
			padding-left: 0;
			background-color: $menu-background;
			width: 100%;
			li {
				display: flex;
				position: relative;
				text-decoration: none;
				transition-duration: 250ms;
				@include no-select;
				@media only screen and (min-width: $menu_breakpoint) {
					float: left;
				}
				&:hover > ul {
					visibility: visible;
					opacity: 1;
					display: block;
					z-index: 9999;
				}
			}
			&--menu-right {
				display: flex;
				flex-basis: 100%;
				@media only screen and (min-width: $menu_breakpoint) {
					flex-basis: auto;
					width: auto;
				}
				li {
					align-self: flex-end;
				}
			}
		}
	}
}
